@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: beige;
}

@font-face {
    font-family: "PP Monument Extended";
    src: url("../../public/fonts/PPMonumentExtended-Regular.woff2")
            format("woff2"),
        url("../../public/fonts/PPMonumentExtended-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PP Monument Extended";
    src: url("../../public/fonts/PPMonumentExtended-RegularItalic.woff2")
            format("woff2"),
        url("../../public/fonts/PPMonumentExtended-RegularItalic.woff")
            format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "PP Monument Extended";
    src: url("../../public/fonts/PPMonumentExtended-BlackItalic.woff2")
            format("woff2"),
        url("../../public/fonts/PPMonumentExtended-BlackItalic.woff")
            format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "PP Monument Extended";
    src: url("../../public/fonts/PPMonumentExtended-LightItalic.woff2")
            format("woff2"),
        url("../../public/fonts/PPMonumentExtended-LightItalic.woff")
            format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "PP Monument Extended";
    src: url("../../public/fonts/PPMonumentExtended-Light.woff2")
            format("woff2"),
        url("../../public/fonts/PPMonumentExtended-Light.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PP Monument Extended";
    src: url("../../public/fonts/PPMonumentExtended-Black.woff2")
            format("woff2"),
        url("../../public/fonts/PPMonumentExtended-Black.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

h1 {
    font-size: 3vw;
    @include mobile {
        font-size: 24px;
    }
}

h2 {
    font-size: 22px;
    @include mobile {
        font-size: 16px;
    }
}
p {
    font-size: 14px;
    line-height: 16px;
    @include mobile {
        font-size: 10px;
    }
}
a {
    all: unset;
    cursor: pointer;
}
a:hover {
    text-decoration: underline;
}

.container {
    display: flex;
    justify-content: center;
    background-color: $beige;
    width: 100%;
}

.contain {
    width: min(100%, 1800px);
}
