@import "../../scss/mixin.scss";

.introvid {
    width:100%;
    height:100vh;
    
    @include tablet {
        height:auto;
    }
    
    video {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit:cover;
        object-position: 50% 50%;
        
        @include tablet {
            position:relative;
            height:auto;
            display:block;
        }
    }
}

.animation-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-self: center;
    align-items: center;
    background-color: $beige;
    svg {
        @include mobile {
            width:  130vw !important;
            margin-left: -15vw;
        }
    }
    display:none;
}

.footer {
    font-family: "PP Monument Extended";
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    text-transform: uppercase;
    padding: 3rem;
    @include mobile {
        padding: 3rem 1.5rem 1.5rem 1.5rem;
    }
    color: $green;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    @include mobile {
        height: fit-content;
    }
    .top-half {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .copy {
            padding-bottom: 2em;
        }
        .loc-copy {
            padding-bottom: 2em;
        }
    }
    .pink-rec {
        width: 190px;
        height: 18px;
        background-color: $pink;
        margin-bottom: 1em;
    }
    .bottom-half {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 1em;
        .conc {
            padding-bottom: 1em;
        }
        .agents-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            @include mobile {
                width: 100%;
            }
            img {
                padding: 1em 0;
                width: 98px;
            }
            .agents:first-child {
                padding-right: 1.5rem;
            }
            .agent {
                padding-bottom: 1em;
                .pink {
                    color: $pink;
                }
            }
        }
    }
}
